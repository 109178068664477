import React from "react"
import "../scss/site.scss"
import PropTypes from "prop-types"
import Footer from "./footer"


const Layout = ({ children, footer }) => {
  return (
    <div className="dark-wrapper">
      <div className="scrolling-wrapper">
        <main>
          {children}
          <Footer cta={footer} />
        </main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
