import React, { useCallback } from 'react'
import gsap from 'gsap'
export default function Carousel({ children, timing = 35 }) {

    const carsouel = useCallback((el) => {
        if (el !== undefined) {
            const tl = gsap.timeline({ repeat: -1 })
            tl.fromTo(el, { transform: `translateX(0%)` }, { transform: `translateX(-50%)`, duration: 15, ease: "linear" })
        }
    },[])
    return (
        <div className='max-100p overflow-hidden flex'>
            <div ref={carsouel} className='flex no-wrap animate-carosuel ' style={{ whiteSpace: 'nowrap' }} >
                <div>&nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;</div>
                {children}
                <div>&nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;</div>
                {children}

            </div>
        </div>
    )
}
