import React from 'react'


export default function Overlay({ children, className, ...props }) {
    return (
        <div className={'bg-image ' + className} {...props}>
            {children}
        </div>

    )
}
