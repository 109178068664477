import React from "react"
import { useButtonText } from "../hooks/useButtonText"
import useFooterData from "../hooks/useFooterData"
import { useLanguage } from "../hooks/useLanguage"
import { ReactComponent as Money } from "../images/money.svg"
import CtaButton from "./ctaButton"
import { H2, P } from "./fonts"
import Link from "./link"
import Overlay from "./overlay"
import OverlayConatiner from "./overlayConatiner"
import VideoOrImage from "./videoOrImage"

export default function Cta() {
    const { footerImage, footerTitle, footerTitleJp, footerContent, footerContentJp, footerVideoUrl, footerMobileImage, footerMobileVideo } = useFooterData()
    const btnText = useButtonText()
    const { switchLanguage } = useLanguage()
    return (
        <Link to='/contact' className='hover-image--zoom link'>
            <OverlayConatiner className='pt50p bg-grey t-pt100 m-pt150 m-br0'>
                <Overlay className='z-2  flex flex-col  p5p border-box justify-center pos-abs m-justify-start m-pt30 m-pb30'>
                    <Money className='mb30 fill-yellow-green m-mb10' />
                    <H2 className='m0 max-450 m-max-300 mb30 m-mb20'>{switchLanguage(footerTitleJp, footerTitle)}</H2>
                    <P className='mb60 max-450 m-max-300 m0 m-mb20'>{switchLanguage(footerContentJp, footerContent)}</P>
                    <div className='flex'>
                        <CtaButton children={switchLanguage(btnText.titleJp, btnText.title)} />
                    </div>
                </Overlay>
                <VideoOrImage className='z-1 cover flex bg-image filter-brightness' mobileImage={footerMobileImage} mobileVideo={footerMobileVideo} videoWhen={footerVideoUrl} video={footerVideoUrl} image={footerImage} />
            </OverlayConatiner>
        </Link>
    )
}
