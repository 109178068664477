import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useLanguage } from "../hooks/useLanguage"
import { ReactComponent as Logo } from "../images/f-logo.svg"
import { H3, P, Tiny } from "./fonts"
import Link from "./link"
import MenuLink from "./menuLink"

export default function FooterInfo() {
    const { footer } = useStaticQuery(graphql`
        query FooterInfoQuery {
            footer: datoCmsFooter {
                links {
                    id
                    title
                    titleJp
                    link
                }
                addresses {
                    id
                    address
                    contactLink
                    linkText
                    linkTextJp
                }
                runsOverline
                runsTitle
                runsOverlineJp
                runsTitleJp
                runText
                runTextJp
                runLink
                socials {
                    id
                    link
                    icon {
                        url
                    }
                }
            }
        }
    `)
    const { links, addresses, runsOverline, runsOverlineJp, runsTitleJp, runTextJp, runsTitle, runText, runLink, socials } = footer
    return (
        <div className='flex flex-col m-pt0 m-pl20 m-pr20'>
            <div className='grid-cols-1-auto grid grid-rows-1-auto pt60 pb80 bt1 bb1 gap-y-160 m-pt30 m-pb40'>
                <div className='grid footer-grid'>
                    <Menu links={links} />
                    <div className='m-hide' />
                    {/* <SecondMenu links={links} /> */}
                    <Addresses addresses={addresses} />
                </div>
                <div className='flex justify-end'>
                    <Logo className='fill-grey m-hide' />
                </div>
                <PoweredBy overline={runsOverline} overlineJapanese={runsOverlineJp} titleJapanese={runsTitleJp} buttonTextJapanese={runTextJp} title={runsTitle} buttonText={runText} link={runLink} />
                <div className='flex align-bottom'>
                    {socials.map(social => (
                        <a aria-label='Social Link' key={social.link} href={social.link} target='_blank' rel='noreferrer'>
                            <div className='social-icon mr5'>
                                <img src={social.icon.url} alt='' className='w-100 h-100' />
                            </div>
                        </a>
                    ))}
                </div>
            </div>
            <Terms />
        </div>
    )
}

function SecondMenu({ links }) {
    return (
        <div>
            {links.map(link => (
                <SecondaryLink link={link.link} key={link.id}>
                    {link.title}
                </SecondaryLink>
            ))}
        </div>
    )
}

function Terms() {
    const { switchLanguage } = useLanguage()
    return (
        <div className='flex justify-between pb30 pt30  text-grey m-pt20 m-pb20'>
            <Tiny>
                ©{new Date().getFullYear()} FSCO {switchLanguage("全著作権所有", "All Rights Reserved")}
            </Tiny>
            <Tiny className='flex'>
                <span className='mr5'>{switchLanguage("ウェブサイト ", "Site by ")}</span>
                <a rel='noreferrer' target='_blank' href='https://groundcrew.com.au/' className={"groundcrew-link"}>
                    Groundcrew
                </a>
            </Tiny>
        </div>
    )
}

function Menu({ links }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className='flex flex-col'>
            {links.map(({ title, titleJp, link }) => {
                return (
                    <MenuLink key={link} textClassName='text-grey m-font-medium' className='dark mra ' to={link}>
                        {switchLanguage(titleJp, title)}
                    </MenuLink>
                )
            })}
        </div>
    )
}

function PoweredBy({ overline, overlineJapanese, titleJapanese, buttonTextJapanese, title, buttonText, link }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className=''>
            <P className='text-grey '>{switchLanguage(overlineJapanese, overline)}</P>
            <P className='mb20 text-green-blue m-mb10 m-pr20'>{switchLanguage(titleJapanese, title)}</P>
            <a href={link} target='_blank' rel='noopener noreferrer' className='arrow-link green small'>
                {switchLanguage(buttonTextJapanese, buttonText)}
            </a>
        </div>
    )
}

function Addresses({ addresses }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className='flex flex-col gap-30'>
            {addresses.map(address => {
                const isInternalLink = address.contactLink[0] === "/"
                const As = isInternalLink ? Link : "a"
                const props = isInternalLink ? { to: address.contactLink } : { target: "_blank", rel: "noreferrer", href: address.contactLink }
                return (
                    <div key={address.id}>
                        <div dangerouslySetInnerHTML={{ __html: address.address }} className='text-grey tiny mb10' />
                        <As {...props} className='arrow-link green small'>
                            {switchLanguage(address.linkTextJp, address.linkText)}
                        </As>
                    </div>
                )
            })}
        </div>
    )
}

function SecondaryLink({ link, children }) {
    const isInternalLink = link[0] === "/"
    const As = isInternalLink ? Link : "a"
    const props = isInternalLink ? { activeClassName: "active", to: link } : { target: "_blank", rel: "noreferrer", href: link }

    return (
        <As {...props} className={"h3 link op-link"}>
            <H3 className={""}>{children}</H3>
        </As>
    )
}
