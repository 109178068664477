import { graphql, useStaticQuery } from "gatsby"

export const useButtonText = () => {
    const { btnText } = useStaticQuery(graphql`
        query ButtonText {
            btnText: datoCmsEnquireButton {
                title
                titleJp
                readTitle
                readTitleJp
            }
        }
    `)
    return btnText
}
