import React from 'react'
import classNames from 'classnames'

export default function OverlayConatiner({ as = 'div', children, className, radius = '10', ...props }) {
    const As = as

    const overlayClassNames = classNames(
        'pos-rel',
        'overflow-hidden',
        `br-${radius}`,
        className
    )

    return (
        <As className={overlayClassNames} {...props}>
            {children}
        </As>
    )
}
