import { graphql, useStaticQuery } from "gatsby"

export default function useFooterData() {
    const { data } = useStaticQuery(graphql`
        query Footer {
            data: datoCmsFooter {
                footerTitle
                footerTitleJp
                footerContent
                footerContentJp
                footerImage {
                    gatsbyImageData
                }
                footerMobileImage {
                    gatsbyImageData
                }
                footerVideoUrl
                footerMobileVideo: footerMobileVideoUrl
            }
        }
    `)
    return data
}
