import React from 'react'



export function H1({ children, ...props }) {
    return (
        <h1 {...props}>{children}</h1>
    )
}

export function H2({ children, ...props }) {
    return (
        <h2 {...props}>{children}</h2>
    )
}

export function H3({ children, ...props }) {
    return (
        <h3 {...props}>{children}</h3>
    )
}

export function H4({ children, className, as = 'h4', ...props }) {
    const As = as
    return (
        <As className={'h4 ' + className} {...props}>{children}</As>
    )
}

export function P({ children, ...props }) {
    return (
        <p {...props}>{children}</p>
    )
}

export function ELargeText({ children, className, ...props }) {
    return (
        <p className={'e-large-text ' + className} {...props}>{children}</p>
    )
}

export function Tiny({ children, className, as = 'p', ...props }) {
    const As = as
    return (
        <As className={'tiny ' + className} {...props}>{children}</As>
    )
}