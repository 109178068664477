import React from 'react'
import { useChangeInView } from '../context/menuContext'

export default function ChangeHeaderInView({ children, color, inital = false, threshold, className, ...props }) {
    const { ref } = useChangeInView({ enterTo: color, inital, threshold, ...props })
    return (
        <div ref={ref} className={className} >
            {children}
        </div>
    )
}
