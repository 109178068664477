import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"
import Carousel from "./carousel"
import ChangeHeaderInView from "./changeHeaderinView"
import Cta from "./cta"
import { ELargeText } from "./fonts"
import FooterInfo from "./footerInfo"
import ConditionallyRender from "./utils/conditionallyRender"

export default function Footer({ cta = true }) {
    const { footer } = useStaticQuery(graphql`
        query FooterQuery {
            footer: datoCmsFooter {
                scrollingText
                scrollingTextJp
            }
        }
    `)

    const { switchLanguage } = useLanguage()

    return (
        <ChangeHeaderInView threshold={0.2} color={COLOR_STATES.DARK_WHITE_BG}>
            <footer className='text-white'>
                <div className='bg-white '>
                    <ConditionallyRender when={cta}>
                        <div className='pt160 pl60 pr60 max-1600 ma m-p0'>
                            <Cta />
                        </div>
                    </ConditionallyRender>
                    <div className='pt120 pb120 m-pt60 m-pb60'>
                        <Carousel>
                            <ELargeText className='text-grey'>{switchLanguage(footer.scrollingTextJp, footer.scrollingText)}</ELargeText>
                        </Carousel>
                    </div>
                </div>
                <div className='max-1600 ma pl60 pr60 m-p0'>
                    <FooterInfo />
                </div>
            </footer>
        </ChangeHeaderInView>
    )
}
