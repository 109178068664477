import Link from './link'
import React from 'react'
import { H3 } from './fonts'
import classNames from 'classnames'

export default function MenuLink({ children, className, textClassName, whiteDotClassName, as = Link, ...props }) {
  const As = as

  const linkClassNames = classNames(
    'menu--link circle-link link',
    'flex',
    'link',
    'h3',
    className
  )

  return (
    <As activeClassName='active' className={linkClassNames} {...props}>
      <H3 className={textClassName}>{children}</H3>
    </As>
  )
}
